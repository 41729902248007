import * as React from 'react';
import styled from 'styled-components';
import { Link } from '@latitude/link';
import {
  BUTTON_STYLE,
  ALIGN_ITEMS,
  BREAKPOINT,
  ICON_SIZE,
  ICON_VARIANT,
  COLOR,
  FONT_SIZE,
  LINE_HEIGHT,
  FONT_WEIGHT,
  MARGIN
} from '@latitude/core/utils/constants';

import { Box, Flex } from '@latitude/box';
import { Heading1 } from '@latitude/heading';
import { Text } from '@latitude/text';
import { formatTelNo } from '@latitude/tel';
import Icon from '../Icon/Icon';

const HeroBranded = ({
  buttonProps,
  className,
  children,
  fastApply,
  footnote,
  imageContent,
  orCallUs,
  title,
  text,
  transparentBg,
  simple,
  getAResponse,
  breadcrumbs,
  bgConfetti
}) => (
  <HeroContainer className={className} bgConfetti={bgConfetti}>
    <HeroInner>
      {!simple && <HeroImage>{imageContent}</HeroImage>}
      <HeroContent
        simple={simple}
        className="HeroContent"
        transparentBg={transparentBg}
      >
        {children || (
          <HeroContentInner isResponsive>
            {breadcrumbs && (
              <AnchorWrapper className="mb-4">
                {breadcrumbs.map((breadcrumb, index) => (
                  <strong>
                    <StyledAnchor
                      href={breadcrumb.href}
                      className={
                        index === breadcrumbs.length - 1 ? 'inactive' : ''
                      }
                    >
                      {breadcrumb.name}
                      {index !== breadcrumbs.length - 1 ? (
                        <Seperator className="pl-1 pr-1">/</Seperator>
                      ) : (
                        void 0
                      )}
                    </StyledAnchor>
                  </strong>
                ))}
              </AnchorWrapper>
            )}
            {title && <HeroTitle>{title}</HeroTitle>}
            {text && <HeroText>{text}</HeroText>}
            {getAResponse && <GetAResponse />}
            {buttonProps && (
              <Flex
                css={`
                  flex-wrap: wrap;
                  margin-top: -${MARGIN.M24};
                  @media (min-width: ${BREAKPOINT.LG}) {
                    margin-top: -${MARGIN.M32};
                  }
                  > * {
                    margin-top: ${MARGIN.M24};
                    @media (min-width: ${BREAKPOINT.LG}) {
                      margin-top: ${MARGIN.M32};
                    }
                  }
                `}
              >
                <HeroButton
                  button={buttonProps.type || BUTTON_STYLE.PRIMARY}
                  {...buttonProps}
                />
                {fastApply && <HeroFastApply />}
                {orCallUs && <HeroOr />}
                {orCallUs && <HeroCallUs orCallUs={orCallUs} />}
              </Flex>
            )}
            {footnote && <HeroFootnote>{footnote}</HeroFootnote>}
          </HeroContentInner>
        )}
      </HeroContent>
    </HeroInner>
  </HeroContainer>
);

const HeroContainer = styled(Box)`
  position: relative;
  padding: 0;
  overflow: hidden;
  min-height: 280px;
  ${props =>
    props.bgConfetti &&
    `background-image: url(${require('../../images/bg-confetti.svg')});
      background-repeat: no-repeat;
      background-position: 160px 280px;`};
`;

const HeroInner = styled(Box)`
  position: relative;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;

  @media (min-width: ${BREAKPOINT.MD}) {
    flex-direction: row;
    justify-content: space-between;
    max-width: calc(${BREAKPOINT.MD} - 30px);
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    max-width: calc(${BREAKPOINT.LG} - 30px);
  }

  @media (min-width: ${BREAKPOINT.XL}) {
    max-width: calc(1140px - 30px);
  }
`;

export const HeroImage = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 280px;

  @media (min-width: ${BREAKPOINT.MD}) {
    width: 50%;
    height: auto;
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    width: 50%;
  }
`;

export const HeroContent = styled.div`
  position: relative;
  z-index: 2;
  padding: 24px 0;
  background-color: ${props => (props.transparentBg ? 'transparent' : '#fff')};

  @media (min-width: ${BREAKPOINT.MD}) {
    width: 50%;
    padding: 0 0 48px;
    padding: 80px 0 48px;
    background-color: transparent;
    order: -1;
  }
  ${props =>
    props.simple &&
    `
      background: transparent;
      @media (min-width: 0) {
        padding: 114px 0
      }
      @media (min-width: ${BREAKPOINT.LG}) {
        padding: 100px 0
      }`}
`;

const HeroContentInner = styled(Box)`
  margin: 0 auto;
`;

export const HeroTitle = styled(Heading1)`
  font-size: 32px;
  line-height: 36px;
  font-weight: 600;
  margin-bottom: 16px;
  color: ${COLOR.BLACK};

  @media (min-width: ${BREAKPOINT.LG}) {
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 24px;
  }
`;

export const HeroText = styled(Text)`
  /* max-width: 420px; */
  margin-bottom: 24px;
  font-size: ${FONT_SIZE.NORMAL};
  line-height: ${LINE_HEIGHT.STANDARD.SMALL};
  color: ${COLOR.GREY75};

  @media (min-width: ${BREAKPOINT.LG}) {
    margin-bottom: 32px;
    margin-right: 32px;
    font-size: ${FONT_SIZE.LARGE};
    line-height: 27px;
  }
`;

const HeroButton = styled(Link)`
  max-width: 200px;
`;

const HeroFastApply = () => (
  <Flex alignItems={ALIGN_ITEMS.CENTER} marginLeft={MARGIN.M24}>
    <Box paddingRight="12px" marginBottom="-6px">
      <Icon
        variant={ICON_VARIANT.STOPWATCH}
        size={ICON_SIZE.SM}
        color={COLOR.BLACK}
      />
    </Box>
    <Text
      color={COLOR.GREY75}
      fontWeight={FONT_WEIGHT.NORMAL}
      lineHeight={LINE_HEIGHT.TIGHT}
    >
      Apply in less
      <br />
      than 10 minutes
    </Text>
  </Flex>
);

const HeroOr = () => (
  <Flex alignItems={ALIGN_ITEMS.CENTER} margin={`0 ${MARGIN.M16}`}>
    <Text fontWeight={FONT_WEIGHT.NORMAL}>Or</Text>
  </Flex>
);

const HeroCallUs = ({ orCallUs }) => (
  <Flex>
    <Link
      button={BUTTON_STYLE.TERTIARY}
      href={`tel:${formatTelNo(orCallUs)}`}
      width="190px"
      trackId="hero-call-us-link"
    >
      {`Call us on ${orCallUs}`}
    </Link>
  </Flex>
);

const GetAResponse = () => (
  <div className="row mb-4">
    <div className="col-6">
      <Flex alignItems={ALIGN_ITEMS.CENTER}>
        <Box paddingRight="10px" marginBottom="-6px">
          <Icon
            variant={ICON_VARIANT.TICK_RESPONSE}
            size={ICON_SIZE.SM}
            color={COLOR.BLACK}
          />
        </Box>
        <Text
          color={COLOR.GREY75}
          fontWeight={FONT_WEIGHT.NORMAL}
          lineHeight={LINE_HEIGHT.TIGHT}
          marginTop="2px"
        >
          Get a response in 60 seconds
        </Text>
      </Flex>
    </div>
    <div className="col-6">
      <Flex alignItems={ALIGN_ITEMS.CENTER}>
        <Box paddingRight="10px" marginBottom="-4px">
          <Icon
            variant={ICON_VARIANT.STOPWATCH}
            size={ICON_SIZE.SM}
            color={COLOR.BLACK}
          />
        </Box>
        <Text
          color={COLOR.GREY75}
          fontWeight={FONT_WEIGHT.NORMAL}
          lineHeight={LINE_HEIGHT.TIGHT}
          marginTop="2px"
        >
          Just 10 minutes to apply
        </Text>
      </Flex>
    </div>
  </div>
);

const HeroFootnote = styled(Box)`
  font-size: ${FONT_SIZE.SMALL};
  font-weight: ${FONT_WEIGHT.LIGHT};
  line-height: ${LINE_HEIGHT.TIGHT.SMALL};
  margin-top: ${MARGIN.M24};
  @media (min-width: ${BREAKPOINT.LG}) {
    margin-top: ${MARGIN.M40};
  }
`;

const StyledAnchor = styled.a`
  text-decoration: none;
  color: ${COLOR.BLUE_DEEP} !important;
  font-weight: 400;
  border-bottom: none !important;
  &.inactive {
    color: ${COLOR.GREY75} !important;
    cursor: default !important;
    &:hover,
    &:active {
      text-shadow: none !important;
    }
  }
`;

const AnchorWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (min-width: ${BREAKPOINT.LG}) {
    margin-top: -${MARGIN.M32};
  }
`;

const Seperator = styled.span`
  color: ${COLOR.GREY75};
`;

export default HeroBranded;
