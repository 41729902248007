import styled from 'styled-components';
import {
  COLOR,
  BREAKPOINT,
  FONT_FAMILY,
  FONT_WEIGHT
} from '@latitude/core/utils/constants';
import { FeaturesAndBenefitsSlider } from '@latitude/features-and-benefits-slider';

const FeaturesSlider = styled(FeaturesAndBenefitsSlider)`
  && {
    padding-top: 48px;
    padding-bottom: 48px;

    @media (min-width: ${BREAKPOINT.LG}) {
      padding-top: 72px;
      padding-bottom: 72px !important;
    }

    @media (max-width: ${BREAKPOINT.LG}) {
      .container.section__content {
        margin: 0;
        padding: 0;
        max-width: 100%;
      }
    }

    .loan-used-for-categories__title,
    .card-icon__title {
      font-family: ${FONT_FAMILY.TITLE};
      color: ${COLOR.BLACK};
      font-weight: ${FONT_WEIGHT.SEMI_BOLD};
      margin-top: 0 !important;
    }

    .loan-used-for-categories__title {
      font-size: 24px;
      line-height: 32px;
      @media (min-width: ${BREAKPOINT.LG}) {
        font-size: 32px;
      }
    }

    .lfs-slide {
      background-color: #fff;
      padding-top: 0 !important;
      padding-bottom: 38px !important;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
    }

    .card-icon {
      padding-top: 0;
      padding-bottom: 0;
    }

    .card-icon__image {
      position: relative;
      z-index: 0;
      margin-left: -16px;
      width: calc(100% + 32px);
      max-height: 192px;
      overflow: hidden;
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 35px;
        transform: skew(0, -5deg);
        background: #fff;
        transform-origin: top left;
        position: absolute;
        bottom: -26px;
      }
      img {
        border-radius: 0 !important;
        margin-bottom: 0 !important;
      }
    }

    .card-icon__title {
      @media (max-width: ${BREAKPOINT.LG}) {
        line-height: 24px;
      }
    }

    .card-icon__title,
    .card-icon__content {
      padding: 0;
    }

    .card-icon__title {
      font-size: 18px;
      @media (min-width: ${BREAKPOINT.LG}) {
        font-size: 20px;
      }
    }

    @media (min-width: ${BREAKPOINT.LG}) {
      .slick-track {
        padding-bottom: 60px !important;
        .slick-slide:nth-child(2n + 2) {
          transform: translateY(72px);
        }
      }
    }
  }
`;

export default FeaturesSlider;
